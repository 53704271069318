import { JobHealthPerformance } from '@upper/graphql/internal';
import chroma from 'chroma-js';
import { JOB_HEALTH_COLORS } from '../const/job';

export const jobHealthColorForValue = (
  value: JobHealthPerformance
): { background: string; color: string } => {
  const roundedHundredPercentage = value
  let background = JOB_HEALTH_COLORS.green
  if (roundedHundredPercentage === JobHealthPerformance.Mediocre) {
    background = JOB_HEALTH_COLORS.yellow
  } else if (roundedHundredPercentage === JobHealthPerformance.Bad) {
    background = JOB_HEALTH_COLORS.red
  }

  const color = chroma(background).darken(1.8).hex()
  return { background, color }
}
