import { JobHealthBaseFragment } from '@upper/graphql/internal'
import { classNames, Text } from '@upper/ui'
import { fullPercentageForValue } from '@upper/utils'
import { forwardRef } from 'react'
import { jobHealthColorForValue } from '../../utils/job'

type JobHealthInfoProps = Omit<JobHealthBaseFragment, '__typename'>
export const JobHealthInfo = forwardRef<any, JobHealthInfoProps>(
  (
    {
      percentage,
      performance,
      daysToStaffingDeadline,
      stage1Count,
      stage1Percentage,
      stage1Performance,
      stage1Threshold,
      stage2Count,
      stage2Percentage,
      stage2Performance,
      stage2Threshold,
      stage3Count,
      stage3Percentage,
      stage3Performance,
      stage3Threshold,
      stage4Count,
      stage4Percentage,
      stage4Performance,
      stage4Threshold,
      deadlineInDays,
    },
    ref
  ) => {
    const fullPercentage = fullPercentageForValue(percentage)
    const colors = jobHealthColorForValue(performance)

    return (
      <div ref={ref} className="text-gray-dark min-w-[200px] px-2">
        <header className="border-gray-light flex items-center justify-between border-b py-1">
          <Text variant="subtitle" className="text-gray-darkest">
            Job Health
          </Text>
          <div
            ref={ref}
            className={classNames(
              'inline-flex h-7 w-7 items-center justify-center rounded-md font-mono'
            )}
            style={{ color: colors.background }}
          >{`${fullPercentage}%`}</div>
        </header>
        <div className="space-y-1 py-1 text-sm">
          <InfoRow
            label="Deadline"
            value={`${deadlineInDays - daysToStaffingDeadline ?? 0}/${
              deadlineInDays ?? 0
            }`}
            caption="days"
          />
          <InfoRow
            label="Invited"
            value={`${stage1Count ?? 0}/${stage1Threshold ?? 0}`}
            caption="candidates"
          />
          <InfoRow
            label="Matching"
            value={`${stage2Count ?? 0}/${stage2Threshold ?? 0}`}
            caption="candidates"
          />
          <InfoRow
            label="Proposed"
            value={`${stage3Count ?? 0}/${stage3Threshold ?? 0}`}
            caption="candidates"
          />
          <InfoRow
            label="Interview"
            value={`${stage4Count ?? 0}/${stage4Threshold ?? 0}`}
            caption="candidates"
          />
        </div>
      </div>
    )
  }
)
JobHealthInfo.displayName = 'JobHealthInfo'

type InfoRowProps = { label: string; value: string; caption?: string }
const InfoRow = ({ label, value, caption }: InfoRowProps) => {
  return (
    <div className="-mx-2 grid grid-cols-2 items-center justify-between rounded-lg bg-slate-50 px-2">
      <p className="text-xs font-medium text-slate-500">{label}</p>
      <p className="flex flex-col items-end justify-end">
        {value}
        {caption && (
          <span className="text-gray top-full -mt-1 text-xs font-light">
            {caption}
          </span>
        )}
      </p>
    </div>
  )
}
