import { JobHealthBaseFragment } from '@upper/graphql/internal'
import { classNames } from '@upper/ui'
import { fullPercentageForValue } from '@upper/utils'
import { forwardRef, useMemo } from 'react'
import { jobHealthColorForValue } from '../../utils/job'

type JobHealthBadgeProps = Omit<JobHealthBaseFragment, '__typename'>
export const JobHealthBadge = forwardRef<any, JobHealthBadgeProps>(
  ({ percentage, performance }, ref) => {
    const fullPercentage = fullPercentageForValue(percentage)
    const colors = jobHealthColorForValue(performance)

    const Icon = useMemo(() => {
      if (fullPercentage >= 40 && fullPercentage < 70) {
        return YellowIcon
      } else if (fullPercentage >= 70) {
        return GreenIcon
      }
      return RedIcon
    }, [fullPercentage])

    return (
      <div
        ref={ref}
        className={classNames(
          'inline-flex h-8 w-8 flex-none cursor-help items-center justify-center rounded-full p-1 font-mono text-xs'
        )}
        style={{ ...colors }}
      >
        <Icon />
      </div>
    )
  }
)
JobHealthBadge.displayName = 'JobHealthBadge'

const YellowIcon = () => (
  <svg
    width="12"
    height="5"
    viewBox="0 0 12 5"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.6875 2.5C7.6875 1.58594 6.91406 0.8125 6 0.8125C5.0625 0.8125 4.3125 1.58594 4.3125 2.5C4.3125 3.4375 5.0625 4.1875 6 4.1875C6.91406 4.1875 7.6875 3.4375 7.6875 2.5ZM10.125 0.8125C9.1875 0.8125 8.4375 1.58594 8.4375 2.5C8.4375 3.4375 9.1875 4.1875 10.125 4.1875C11.0391 4.1875 11.8125 3.4375 11.8125 2.5C11.8125 1.58594 11.0391 0.8125 10.125 0.8125ZM1.875 0.8125C0.9375 0.8125 0.1875 1.58594 0.1875 2.5C0.1875 3.4375 0.9375 4.1875 1.875 4.1875C2.78906 4.1875 3.5625 3.4375 3.5625 2.5C3.5625 1.58594 2.78906 0.8125 1.875 0.8125Z"
      fill="white"
    />
  </svg>
)

const GreenIcon = () => (
  <svg
    width="13"
    height="9"
    viewBox="0 0 13 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.05469 8.8125C5.28906 9.04688 5.6875 9.04688 5.92188 8.8125L12.8125 1.92188C13.0469 1.6875 13.0469 1.28906 12.8125 1.05469L11.9688 0.210938C11.7344 -0.0234375 11.3594 -0.0234375 11.125 0.210938L5.5 5.83594L2.85156 3.21094C2.61719 2.97656 2.24219 2.97656 2.00781 3.21094L1.16406 4.05469C0.929688 4.28906 0.929688 4.6875 1.16406 4.92188L5.05469 8.8125Z"
      fill="white"
    />
  </svg>
)

const RedIcon = () => (
  <svg
    width="10"
    height="9"
    viewBox="0 0 10 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.54688 4.5L8.89062 2.15625C9.19531 1.875 9.19531 1.40625 8.89062 1.125L8.375 0.609375C8.09375 0.304688 7.625 0.304688 7.34375 0.609375L5 2.95312L2.63281 0.609375C2.35156 0.304688 1.88281 0.304688 1.60156 0.609375L1.08594 1.125C0.78125 1.40625 0.78125 1.875 1.08594 2.15625L3.42969 4.5L1.08594 6.86719C0.78125 7.14844 0.78125 7.61719 1.08594 7.89844L1.60156 8.41406C1.88281 8.71875 2.35156 8.71875 2.63281 8.41406L5 6.07031L7.34375 8.41406C7.625 8.71875 8.09375 8.71875 8.375 8.41406L8.89062 7.89844C9.19531 7.61719 9.19531 7.14844 8.89062 6.86719L6.54688 4.5Z"
      fill="white"
    />
  </svg>
)
